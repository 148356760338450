import { trigger, state, style, transition, animate } from '@angular/animations';

export const hamburguerXAnimation = trigger('hamburguerX', [
  /*
    state 'hamburguer' is the regular 3 lines style.
    states 'topX', 'hide', and 'bottomX' are used to style the X element
  */
  state('hamburguer', style({})),
  // style top bar to create the X
  state(
    'topX',
    style({
      transform: 'rotate(45deg)',
      transformOrigin: 'left',
      width: '28px',
      margin: '8px',
      borderRadius: '20px',
      backgroundColor: '#ec0000',
    })
  ),
  // hides element when create the X (used in the middle bar)
  state(
    'hide',
    style({
      opacity: 0,
    })
  ),
  // style bottom bar to create the X
  state(
    'bottomX',
    style({
      transform: 'rotate(-45deg)',
      transformOrigin: 'left',
      width: '28px',
      margin: '8px',
      borderRadius: '20px',
      backgroundColor: '#ec0000',
    })
  ),
  transition('* => *', [
    animate('.2s'), // controls animation speed
  ]),
]);
