<ng-container *ngIf="offers.length > 2; else noCarousel">
  <div class="keen-slider" #sliderRef>
    <app-offer-card
      [offer]="offer"
      *ngFor="let offer of offers; index as i"
      class="keen-slider__slide number-slide{{ i + 1 }} mobile-carousel-card">
    </app-offer-card>
  </div>
</ng-container>
<ng-template #noCarousel>
  <div class="util__centering-flexbox-container">
    <app-offer-card [offer]="offer" *ngFor="let offer of offers; index as i"></app-offer-card>
  </div>
</ng-template>
