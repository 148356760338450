<header [ngClass]="{ inside: isAuthenticated }" [style.background-image]="setAdminBanner()">
  <app-mobile-nav class="sm-visible"></app-mobile-nav>
  <div class="header-wrapper">
    <div appScrollFixNavbar class="navbar">
      <nav *ngIf="isAuthenticated; else publicNav">
        <ul class="util__centering-flexbox-container">
          <li><a class="link" (click)="navigate('welcome', 'how-to-use')">Jak to działa?</a></li>
          <li><a class="link" (click)="navigate('welcome', 'partners')">Nasi partnerzy</a></li>
          <li><a class="link" routerLink="order-history">Historia zamówień</a></li>
          <li>
            <ng-container *ngIf="totalCartQuantity$ | async as total; else noItem">
              <button routerLink="/cart" class="button primary small">Twój koszyk: {{ total }}</button>
            </ng-container>
            <ng-template #noItem>
              <button routerLink="/cart" class="button small">Twój koszyk</button>
            </ng-template>
          </li>
          <li>
            <button (click)="logout()" class="button primary small">Wyloguj się</button>
          </li>
        </ul>
      </nav>
      <ng-template #publicNav>
        <nav>
          <ul class="util__centering-flexbox-container">
            <li><a class="link" (click)="navigate('welcome', 'how-to-use')">Jak to działa?</a></li>
            <li><a class="link" (click)="navigate('welcome', 'partners')">Nasi partnerzy</a></li>
            <li><a class="link" routerLink="forms/partner">Zostań partnerem</a></li>
            <li>
              <button (click)="login()" class="button primary small">Zaloguj się</button>
            </li>
          </ul>
        </nav>
      </ng-template>
      <div class="util__image-container">
        <img
          class="logo"
          draggable="false"
          (click)="navigate('welcome')"
          src="/assets/images/brands/santander.webp"
          alt="" />
      </div>
    </div>
    <div class="content-wrapper">
      <h1 *ngIf="adminBanner?.heading_text" [innerHTML]="adminBanner.heading_text"></h1>
      <p *ngIf="adminBanner?.additional_text" [innerHTML]="adminBanner.additional_text"></p>
      <button *ngIf="!isAuthenticated" (click)="login()" class="button primary">Zaloguj się do banku</button>
      <button *ngIf="isAuthenticated" (click)="navigate('offers', 'free')" class="button primary">Odkryj okazje</button>
    </div>
  </div>
</header>
